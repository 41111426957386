<script>
import Form from '../../../../../../../components/form';
import request from '../../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const that = this;
    this.rule = await this.getFormRule('sfa_attendance_signgroup_add_type');
    // 序号
    const lineNo = this.getRule('lineNo');
    const lineNoValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('序号不能为空'));
      } else if (Number(value) < 0) {
        callback(new Error('序号不能为负数'));
      } else {
        callback();
      }
    };
    lineNo.validate = [{
      validator: lineNoValidator,
      required: true,
      trigger: 'change',
    }];
    lineNo.props.min = 0;
    console.log('lineNo', lineNo);
    // 打卡开始时间
    const beginTime = this.getRule('beginTime');
    beginTime.props.placeholder = '选择开始时间';
    beginTime.validate = [{
      message: '打卡开始时间不能为空',
      required: true,
      trigger: 'change',
    }];
    // 打卡结束时间
    const endTime = this.getRule('endTime');
    endTime.props.placeholder = '选择结束时间';
    endTime.validate = [{
      message: '打卡结束时间不能为空',
      required: true,
      trigger: 'change',
    }];
    that.hiddenFields(true, ['beginTime']);
    that.hiddenFields(true, ['endTime']);
    // 拍照要求
    const photograph = this.getRule('photograph');
    photograph.options = [{
      label: '是',
      value: 'Y',
    }, {
      label: '否',
      value: 'N',
    }];
    // 时间要求
    const appointTime = this.getRule('appointTime');
    appointTime.options = [{
      label: '是',
      value: 'Y',
    }, {
      label: '否',
      value: 'N',
    }];
    appointTime.on = {
      change(e) {
        if (e === 'Y') {
          that.hiddenFields(false, ['beginTime']);
          that.hiddenFields(false, ['endTime']);
        } else {
          that.hiddenFields(true, ['beginTime']);
          that.hiddenFields(true, ['endTime']);
        }
      },
    };
    this.reload(this.rule);
    if (this.formConfig.type === 'edit') {
      request
        .post('/sfa/sfacheckintype/query', this.formConfig.id)
        .then((res) => {
          if (res.success) {
            const { result } = res;
            if (result.appointTime === 'Y') {
              that.hiddenFields(false, ['beginTime']);
              that.hiddenFields(false, ['endTime']);
            }
            this.setValue(result);
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/sfa/sfacheckintype/save';
        let params = { ...formData };
        if (this.formConfig.type === 'edit') {
          params = Object.assign(this.row, formData);
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
