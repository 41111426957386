<script>
import Form, { formCreate } from '../../../../../../../components/form';

export default {
  extends: Form,
  props: {
    value: Object,
  },
  data() {
    return {
      customerName: '',
    };
  },
  async created() {
    const that = this;
    const rule = await this.getFormRule('sfa_attendance_signgroup_bind_customer');
    this.rule = rule.map((item) => {
      let v = item;
      if (v.field === 'name') {
        v.restful = '/mdm/mdmCustomerMsgController/page';
        v.headers = { functionCode: 'select-customer' };
        v.props = {
          ...v.props,
          remoteParams: 'customerName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          value: 'customerCode',
          label: 'customerName',
        };
        v.on = {
          change: (val) => this.getCustomer(val, v.options),
        };
      } else if (v.field === 'address') {
        v = Object.assign(v, {}, { props: { disabled: true } });
      } else if (v.field === 'signPlaceScope') {
        v.on = {
          change(val) {
            that.$emit('scopeChange', val);
          },
        };
      }
      return v;
    });
    this.reload(this.rule);
    // 默认选中打卡范围 100m
    this.setValue({
      signPlaceScope: '100',
    });
  },
  methods: {
    getCustomer(val, options) {
      const selectCustomer = options.find((v) => v.customerCode === val);
      const { latitude, longitude } = selectCustomer;
      this.setValue({
        name: selectCustomer.customerName,
        address: selectCustomer.registeredAddress,
        placeLatin: latitude,
        placeLngin: longitude,
      });
      this.$emit('customerChange', { latitude, longitude });
    },
  },
};
</script>
