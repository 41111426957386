<template>
  <Modal :modalConfig="modalConfig" @onClose="btnClose">
    <div>
      <Map ref="TMap" :hideSearch="type==='customer'" :circleRadio="circleRadio" @addressChange="handleAddressChange" @addressInit="handleAddressChange"></Map>
    </div>
    <div class="form-map">
      <MapFormPlace ref="mapFormPlaceRef" @scopeChange="scopeChange" v-if="type==='place'"></MapFormPlace>
      <MapFormCustomer ref="mapFormCustomerRef" @scopeChange="scopeChange" @customerChange="customerChange" v-else-if="type==='customer'"></MapFormCustomer>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" icon="el-icon-check" size="small" @click="btnSubmit">提交</el-button>
      <el-button type="danger" icon="el-icon-check" size="small" @click="btnClose">关闭</el-button>
    </div>
  </Modal>
</template>
<script>
import Modal from '../../../../../../../components/modal/components/modal.vue';
import Map from '../../../../../../../components/tencent_map/map.vue';
import MapFormPlace from './map_form_place.vue';
import MapFormCustomer from './map_form_customer.vue';

export default {
  components: {
    Modal,
    Map,
    MapFormPlace,
    MapFormCustomer,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'place',
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.visible = val;
        if (val) {
          // 避免没有重置位置
          setTimeout(() => {
            const { TMap } = this.$refs;
            if (!TMap) return false;
            TMap.initCityLocation();
          }, 1000);
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '打卡地点',
        visible: false,
        type: 'Modal',
        showFooter: false,
      },
      circleRadio: 100, // 默认半径
    };
  },
  methods: {
    /** @desc 地图点击事件 */
    handleAddressChange(placeInfo) {
      if (this.type === 'customer') return false;
      this.$refs.mapFormPlaceRef.setValue({
        name: placeInfo.title,
        address: placeInfo.address,
        placeLatin: placeInfo.lat,
        placeLngin: placeInfo.lng,
      });
    },
    /** @desc 打卡范围变化 */
    scopeChange(value) {
      this.circleRadio = value ? parseInt(value, 10) : 0;
    },
    /** @desc 选择客户变化 */
    customerChange(val) {
      if (val && val.latitude && val.longitude) {
        this.$refs.TMap.moveCenter(val.latitude, val.longitude);
      }
    },
    /** @desc 提交 */
    btnSubmit() {
      let formData = null;
      if (this.type === 'place') {
        formData = this.$refs.mapFormPlaceRef.getFormData();
      } else if (this.type === 'customer') {
        formData = this.$refs.mapFormCustomerRef.getFormData();
      }
      if (!formData) return false;
      console.log('formData:', formData);
      if (!formData.signPlaceScope) {
        formData.signPlaceScope = 100; // 范围非必填， 默认100
      }
      this.$emit('submit', { ...formData });
      this.btnClose();
    },
    /** @desc 关闭 */
    btnClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="less" scoped>
  .form-map {
    margin-top: 20px;
  }
</style>
