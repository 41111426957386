<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <el-row :gutter="24">
        <el-col :md="8">
          <el-select
            v-model="value"
            class="mb20"
            filterable
            clearable
            @clear="getClear"
            @change="getChange($event, 'code')"
            remote
            reserve-keyword
            placeholder="请输入人员名称"
            :remote-method="(query)=>{remoteMethod(query,'customer')}"
            :loading="loading">
            <el-option
              v-for="item in channelOptions"
              :key="item.id"
              :label="item.fullName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                :loading="tableLoading"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkAll"
                :data="tableData">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <template>
            <vxe-table-column field="userName" title="人员编号"></vxe-table-column>
            <vxe-table-column field="realName" title="人员名称"></vxe-table-column>
          </template>
          <vxe-table-column title="操作">
              <template v-slot="{ row }">
                <span class="cursor" @click="delTable(row, 'channel')">删除</span>
              </template>
          </vxe-table-column>
      </vxe-table>

      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalConfig: {
        title: '选择人员',
        visible: true,
        type: 'Modal',
        width: '50%',
      },
      channelOptions: [],
      loading: false,
      tableLoading: false,
      value: '',
      tableData: [],
      selection: [],
    };
  },
  created() {
    this.getCustomer(null);
  },
  methods: {
    // 远程搜索
    remoteMethod(query, type) {
      this.loading = true;
      this.getCustomer(query);
    },
    // 选择人员
    getChange(e, type) {
      if (e) {
        const list = [];
        this.channelOptions.map((v) => {
          if (v.id === e) {
            list.push(v);
          }
          return v;
        });
        this.tableData = this.getReset(this.tableData, list, 'id');
      }
      // if (e) {
      //   this.getCustomer(e, 'change');
      // }
    },
    // 获取人员
    getCustomer(query, type) {
      // let fullName = '';
      // if (query) {
      //   const index = this.channelOptions.findIndex((val) => val.id === query);
      //   if (index > -1) {
      //     fullName = this.channelOptions[index].fullName;
      //   }
      // }
      const data = {
        // positionCode: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        fullName: query,
        enableStatus: '009',
      };
      this.tableLoading = true;
      request.post('/mdm/mdmUserController/findUserSelectList', data).then((res) => {
        this.totalPage = res.result.count;
        if (res.result) {
          res.result.map((v) => {
            const item = v;
            item.realName = item.fullName;
            return item;
          });
        }
        if (type) {
          this.tableData = this.getReset(this.tableData, res.result, 'id');
        } else if (query || query === null) {
          this.channelOptions = res.result;
        }
      }).finally(() => {
        this.loading = false;
        this.tableLoading = false;
      });
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 清除输入框
    getClear() {
      this.value = '';
      this.getCustomer(null);
    },
    // 删除
    delTable(row, type) {
      console.log(row, type);
      const index = this.tableData.findIndex((val) => val.code === row.code);
      if (index > -1) {
        this.tableData.splice(index, 1);
      }
    },
    // 单选
    dialog_checkboxChange({ records }) {
      this.selection = records;
    },
    // 全选
    dialog_checkAll({ records }) {
      this.selection = records;
    },
    // 提交弹窗
    dialog_btnConfirm() {
      if (this.selection.length) {
        this.$emit('submitAdd', this.selection);
      } else {
        this.$message.error('请选择数据');
      }
    },
    // 关闭弹窗
    dialog_btnClose() {
      this.$emit('closeAdd');
    },
  },
};
</script>
<style lang="less" scoped>
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .cursor{
    cursor: pointer;
  }
</style>
