<script>
import Form, { formCreate } from '../../../../../../components/form';
import formAdd from './components';
import request from '../../../../../../utils/request';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('sfa_attendance_signgroup_add');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'form_add') {
        if (this.formConfig.type === 'view') {
          this.buttons.submit = false;
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
            props: {
              disabled: true,
            },
          });
        } else if (this.formConfig.type === 'add') {
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
            props: {
              isAdd: true,
            },
          });
        } else {
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
          });
        }
      }
    });
    this.rule = newRule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .post('/sfa/sfacheckingroup/query', this.formConfig.id)
        .then((res) => {
          if (res.success) {
            const { result } = res;
            if (result.orgRespVos && result.orgRespVos.length > 0) {
              result.orgReqVos = result.orgRespVos.map((v) => ({ ...v, code: v.orgCode, name: v.orgName }));
            }
            result.dateReqVos = result.dateRespVos;
            result.userReqVos = result.userRespVos;
            result.placeReqVos = result.placeRespVos; // 地址数据
            if (result.typeRespVos) {
              result.typeRespVos.map((v) => {
                const item = v;
                item.sfaCheckInTypeId = item.id;
                return item;
              });
              result.groupTypeReqVos = result.typeRespVos;
            }
            const obj = {
              form_add: result,
            };
            this.setValue(obj);
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData().form_add;
      if (formData.orgReqVos && formData.orgReqVos.length > 0) {
        formData.orgReqVos = formData.orgReqVos.map((v) => ({ ...v, orgCode: v.code, orgName: v.name }));
      }
      if (formData) {
        const url = '/sfa/sfacheckingroup/save';
        let params = { ...formData };

        if (this.formConfig.type === 'edit') {
          params = Object.assign(this.row, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
