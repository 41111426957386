var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { staticClass: "formTitle", attrs: { md: 8 } }, [
                _vm._v("基础信息"),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "签到组名称", required: "" },
                    },
                    [
                      _c("el-input", {
                        attrs: { readonly: _vm.disabled },
                        model: {
                          value: _vm.value.groupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "groupName", $$v)
                          },
                          expression: "value.groupName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { staticClass: "formTitle", attrs: { md: 8 } }, [
                _vm._v("签到组规则"),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex search",
                      attrs: { label: "打卡类型", required: "" },
                    },
                    [
                      !_vm.disabled
                        ? _c("i", {
                            staticClass: "el-icon-search",
                            on: { click: _vm.openType },
                          })
                        : _vm._e(),
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.typeNames,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "typeNames", $$v)
                          },
                          expression: "value.typeNames",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex search",
                      attrs: { label: "有效时间", required: "" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.value.timeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "timeType", $$v)
                            },
                            expression: "value.timeType",
                          },
                        },
                        _vm._l(_vm.libData.timeTypeList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value.timeType === "diy_time"
            ? _vm._l(_vm.dateReqVos, function (item, index) {
                return _c(
                  "el-row",
                  { key: index, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: !index ? "选择日期范围" : " " },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "daterange",
                                readonly: _vm.disabled,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getDayChange(index, $event)
                                },
                              },
                              model: {
                                value: item.checkInDate,
                                callback: function ($$v) {
                                  _vm.$set(item, "checkInDate", $$v)
                                },
                                expression: "item.checkInDate",
                              },
                            }),
                            !_vm.disabled
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus-outline pl5",
                                  on: { click: _vm.addDate },
                                })
                              : _vm._e(),
                            index && !_vm.disabled
                              ? _c("i", {
                                  staticClass: "el-icon-remove-outline pl5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delDate(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex search",
                      attrs: { label: "适用范围", required: "" },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "组织", name: "first" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isOrg = true
                                        },
                                      },
                                    },
                                    [_vm._v("添加组织")]
                                  )
                                : _vm._e(),
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData("org", "orgList")
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                              _c(
                                "vxe-table",
                                {
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                    },
                                    data: _vm.orgList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { title: "序号", width: "70px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var rowIndex = ref.rowIndex
                                          return [_vm._v(_vm._s(rowIndex + 1))]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: { field: "code", title: "组织编码" },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: { field: "name", title: "组织名称" },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "org",
                                                            "orgList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3237672893
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "人员", name: "third" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isCustomer = true
                                        },
                                      },
                                    },
                                    [_vm._v("添加人员")]
                                  )
                                : _vm._e(),
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData(
                                            "customer",
                                            "customerList"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                              _c(
                                "vxe-table",
                                {
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                    },
                                    data: _vm.customerList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { title: "序号", width: "70px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var rowIndex = ref.rowIndex
                                          return [_vm._v(_vm._s(rowIndex + 1))]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "userName",
                                      title: "人员编码",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "realName",
                                      title: "人员名称",
                                    },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "customer",
                                                            "customerList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1170710461
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex search",
                      attrs: { label: "打卡地点" },
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSignModal("place")
                                    },
                                  },
                                },
                                [_vm._v("添加地点")]
                              ),
                              _c("span", { staticClass: "mr20" }, [
                                _vm._v(
                                  "可添加多个打卡范围，并且设置电子围栏有效打卡范围"
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSignModal("customer")
                                    },
                                  },
                                },
                                [_vm._v("添加客户")]
                              ),
                              _c("span", [_vm._v("可添加多个客户地址")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c(
                            "vxe-table",
                            {
                              attrs: {
                                "edit-config": {
                                  trigger: "click",
                                  mode: "row",
                                },
                                data: _vm.placeReqVos,
                              },
                            },
                            [
                              _c("vxe-table-column", {
                                attrs: { title: "序号", width: "70px" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var rowIndex = ref.rowIndex
                                      return [_vm._v(_vm._s(rowIndex + 1))]
                                    },
                                  },
                                ]),
                              }),
                              _c("vxe-table-column", {
                                attrs: {
                                  field: "name",
                                  title: "名称",
                                  "min-width": "100",
                                  "show-overflow": "",
                                },
                              }),
                              _c("vxe-table-column", {
                                attrs: {
                                  field: "address",
                                  title: "地址",
                                  "min-width": "100",
                                  "show-overflow": "",
                                },
                              }),
                              _c("vxe-table-column", {
                                attrs: {
                                  field: "signPlaceScope",
                                  title: "范围（米）",
                                  "min-width": "100",
                                  "show-overflow": "",
                                },
                              }),
                              !_vm.disabled
                                ? _c("vxe-table-column", {
                                    attrs: {
                                      title: "操作",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var rowIndex = ref.rowIndex
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "cursor",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delPlace(
                                                        rowIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3242238330
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex search",
                      attrs: { label: "电子围栏" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.value.electronFenceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "electronFenceType", $$v)
                            },
                            expression: "value.electronFenceType",
                          },
                        },
                        _vm._l(_vm.libData.electronFenceList, function (item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.dictCode,
                              attrs: {
                                disabled: _vm.disabled,
                                label: item.dictCode,
                              },
                            },
                            [_vm._v(_vm._s(item.dictValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c("DakaType", { on: { selectRow: _vm.getSelectRow } }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isOrg
        ? _c("OrgDialog", {
            attrs: { orgList: _vm.orgList },
            on: {
              submitOrg: _vm.submitOrg,
              closeOrg: function ($event) {
                _vm.isOrg = false
              },
            },
          })
        : _vm._e(),
      _vm.isCustomer
        ? _c("AddDialog", {
            on: {
              closeAdd: function ($event) {
                _vm.isCustomer = false
              },
              submitAdd: _vm.submitAdd,
            },
          })
        : _vm._e(),
      _c("MapSign", {
        attrs: { visible: _vm.signModalVisible, type: _vm.signType },
        on: {
          "update:visible": function ($event) {
            _vm.signModalVisible = $event
          },
          submit: _vm.signModalSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }