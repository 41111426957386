var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mb20",
                      attrs: {
                        filterable: "",
                        clearable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入人员名称",
                        "remote-method": function (query) {
                          _vm.remoteMethod(query, "customer")
                        },
                        loading: _vm.loading,
                      },
                      on: {
                        clear: _vm.getClear,
                        change: function ($event) {
                          return _vm.getChange($event, "code")
                        },
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.channelOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.fullName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              staticClass: "vxeTable",
              attrs: {
                border: "",
                "row-id": "code",
                loading: _vm.tableLoading,
                data: _vm.tableData,
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkAll,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              [
                _c("vxe-table-column", {
                  attrs: { field: "userName", title: "人员编号" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "realName", title: "人员名称" },
                }),
              ],
              _c("vxe-table-column", {
                attrs: { title: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "cursor",
                            on: {
                              click: function ($event) {
                                return _vm.delTable(row, "channel")
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }