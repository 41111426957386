var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    { attrs: { modalConfig: _vm.modalConfig }, on: { onClose: _vm.btnClose } },
    [
      _c(
        "div",
        [
          _c("Map", {
            ref: "TMap",
            attrs: {
              hideSearch: _vm.type === "customer",
              circleRadio: _vm.circleRadio,
            },
            on: {
              addressChange: _vm.handleAddressChange,
              addressInit: _vm.handleAddressChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-map" },
        [
          _vm.type === "place"
            ? _c("MapFormPlace", {
                ref: "mapFormPlaceRef",
                on: { scopeChange: _vm.scopeChange },
              })
            : _vm.type === "customer"
            ? _c("MapFormCustomer", {
                ref: "mapFormCustomerRef",
                on: {
                  scopeChange: _vm.scopeChange,
                  customerChange: _vm.customerChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-check", size: "small" },
              on: { click: _vm.btnSubmit },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-check", size: "small" },
              on: { click: _vm.btnClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }