<template>
  <div>
    <el-form>
      <el-row :gutter="24">
        <el-col :md="8" class="formTitle">基础信息</el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="签到组名称" required class="flex">
            <el-input v-model="value.groupName" :readonly="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8" class="formTitle">签到组规则</el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="打卡类型" required class="flex search">
            <i class="el-icon-search" @click="openType" v-if="!disabled"></i>
            <el-input v-model="value.typeNames" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="有效时间" required class="flex search">
            <el-select v-model="value.timeType" :disabled="disabled">
              <el-option v-for="item in libData.timeTypeList"
                  :key="item.dictCode"
                  :label="item.dictValue"
                  :value="item.dictCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="value.timeType==='diy_time'">
        <el-row :gutter="24" v-for="(item, index) in dateReqVos" :key="index">
          <el-col :md="12">
            <el-form-item :label="!index?'选择日期范围':' '" class="flex">
              <el-date-picker
                v-model="item.checkInDate"
                value-format="yyyy-MM-dd"
                type="daterange"
                @change="getDayChange(index, $event)"
                :readonly="disabled"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
              <i class="el-icon-circle-plus-outline pl5" @click="addDate" v-if="!disabled"></i>
              <i class="el-icon-remove-outline pl5" v-if="index && !disabled" @click="delDate(index)"></i>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="适用范围" required class="flex search">
            <el-tabs v-model="activeName">
              <el-tab-pane label="组织" name="first">
                <el-button type="primary" v-if="!disabled" size="small" icon="el-icon-plus" @click="isOrg = true">添加组织</el-button>
                <el-button type="danger" v-if="!disabled" size="small" icon="el-icon-delete" @click="clearData('org', 'orgList')">清空</el-button>
                <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" class="mt20" :data="orgList">
                  <vxe-table-column title="序号" width="70px">
                    <template v-slot="{ rowIndex }">{{rowIndex+1}}</template>
                  </vxe-table-column>
                  <vxe-table-column field="code" title="组织编码"></vxe-table-column>
                  <vxe-table-column field="name" title="组织名称"></vxe-table-column>
                  <vxe-table-column title="操作" v-if="!disabled">
                      <template v-slot="{ row }">
                        <span class="cursor" @click="delTable(row, 'org', 'orgList')">删除</span>
                      </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
              <el-tab-pane label="人员" name="third">
                <el-button type="primary" size="small" v-if="!disabled" icon="el-icon-plus" @click="isCustomer = true">添加人员</el-button>
                <el-button type="danger" size="small" v-if="!disabled" icon="el-icon-delete" @click="clearData('customer', 'customerList')">清空</el-button>
                <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" class="mt20" :data="customerList">
                  <vxe-table-column title="序号" width="70px">
                    <template v-slot="{ rowIndex }">{{rowIndex+1}}</template>
                  </vxe-table-column>
                  <vxe-table-column field="userName" title="人员编码"></vxe-table-column>
                  <vxe-table-column field="realName" title="人员名称"></vxe-table-column>
                  <vxe-table-column title="操作" v-if="!disabled">
                      <template v-slot="{ row }">
                        <span class="cursor" @click="delTable(row, 'customer', 'customerList')">删除</span>
                      </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="打卡地点" class="flex search">
            <div v-if="!disabled">
              <el-button type="text" icon="el-icon-circle-plus-outline" @click="showSignModal('place')">添加地点</el-button>
              <span class="mr20">可添加多个打卡范围，并且设置电子围栏有效打卡范围</span>
              <el-button type="text" icon="el-icon-circle-plus-outline" @click="showSignModal('customer')">添加客户</el-button>
              <span>可添加多个客户地址</span>
            </div>
            <div class="mt10">
              <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" :data="placeReqVos">
                <vxe-table-column title="序号" width="70px">
                  <template v-slot="{ rowIndex }">{{rowIndex+1}}</template>
                </vxe-table-column>
                <vxe-table-column field="name" title="名称" min-width="100" show-overflow></vxe-table-column>
                <vxe-table-column field="address" title="地址" min-width="100" show-overflow></vxe-table-column>
                <vxe-table-column field="signPlaceScope" title="范围（米）" min-width="100" show-overflow></vxe-table-column>
                <vxe-table-column title="操作" min-width="100" v-if="!disabled">
                  <template v-slot="{ rowIndex }">
                    <span class="cursor" @click="delPlace(rowIndex)">删除</span>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="电子围栏" class="flex search">
            <!-- 电子围栏用 electronFenceType -->
            <el-radio-group v-model="value.electronFenceType">
              <el-radio :disabled="disabled" v-for="item in libData.electronFenceList" :key="item.dictCode" :label="item.dictCode">{{item.dictValue}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 打卡类型弹窗 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <DakaType @selectRow="getSelectRow"></DakaType>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
    <!-- 组织 -->
    <OrgDialog v-if="isOrg" @submitOrg="submitOrg" @closeOrg="isOrg = false" :orgList="orgList"></OrgDialog>
    <!-- 客户 -->
    <AddDialog v-if="isCustomer"  @closeAdd="isCustomer=false" @submitAdd="submitAdd"></AddDialog>
    <!-- 打卡地点弹窗 -->
    <MapSign :visible.sync="signModalVisible" :type="signType" @submit="signModalSubmit"></MapSign>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';
import Modal from '../../../../../../../components/modal';
import DakaType from './dakaType.vue';
import OrgDialog from './org_dialog.vue';
import AddDialog from './add_dialog.vue';
import MapSign from './map_sign.vue';

export default {
  props: {
    value: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    disabled: Boolean,
    isAdd: Boolean,
  },
  components: {
    Modal,
    DakaType,
    OrgDialog,
    AddDialog,
    MapSign,
  },
  data() {
    return {
      isOrg: false,
      isCustomer: false,
      modalConfig: {
        title: '打卡类型',
        visible: false,
        type: 'Modal',
      },
      activeName: 'first',
      typeList: [],
      orgList: [], // 添加后的组织数据
      customerList: [], // 添加后的客户数据
      placeReqVos: [], //  添加后的地点数据
      dateReqVos: [{
        checkInDate: '',
      }], // 签到日期
      selectRow: [], // 打卡类型数据
      signModalVisible: false, // 打卡地点弹窗显隐
      signType: 'place', // 打卡地点弹窗是打卡地点还是绑定客户
      signRangeList: [],
      libData: {
        timeTypeList: [], // 是有时间类型
        signRangeList: [], // 打卡类型
        electronFenceList: [], // 电子围栏
      }, // 数据字典数据
    };
  },
  created() {
    // console.log(this.disabled);
    // this.$emit('input', this.placeReqVos);
    // this.getFormRule('sfa_attendance_signgroup_add');
    this.getLibData();
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (!this.isAdd) {
          console.log(val);
          this.orgList = val.orgReqVos;
          // this.dateReqVos = val.dateReqVos;
          this.customerList = val.userReqVos;
          this.placeReqVos = val.placeReqVos;
          if (val.dateReqVos && val.dateReqVos.length > 0) {
            this.dateReqVos = val.dateReqVos.map((v) => ({ ...v, checkInDate: [v.startTime, v.endTime] }));
          }
        }
      },
    },
  },
  methods: {
    /** @desc 获取数据字典 */
    getLibData() {
      // 有效时间类型
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'timeType', selectedCode: null }).then((res) => {
        if (!res.success) return;
        this.libData.timeTypeList = res.result;
      });
      // 打卡范围数据字典
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'signPlaceScope', selectedCode: null }).then((res) => {
        if (!res.success) return;
        this.libData.signRangeList = res.result;
      });
      // 打卡范围数据字典
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'electronFence', selectedCode: null }).then((res) => {
        if (!res.success) return;
        this.libData.electronFenceList = res.result;
      });
    },
    /** @desc 显示打卡地点弹窗 */
    showSignModal(key) {
      this.signType = key;
      this.signModalVisible = true;
    },
    /** @desc 打卡地点弹窗提交按钮 */
    signModalSubmit(val) {
      const findRanage = this.libData.signRangeList.find((item) => item.dictCode === val.signPlaceScope);
      const obj = {
        ...val,
        signPlaceScopeDesc: findRanage ? findRanage.dictValue : '',
      };
      this.placeReqVos = this.placeReqVos.concat([obj]);
      this.value.placeReqVos = this.placeReqVos;
      this.$emit('input', this.value);
    },
    /** @desc 删除打卡地点表里的数据 */
    delPlace(index) {
      this.placeReqVos.splice(index, 1);
      this.value.placeReqVos = this.placeReqVos;
      this.$emit('input', this.value);
    },
    // 打卡类型弹窗
    openType() {
      this.modalConfig.visible = true;
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
    closeAddPlaceModal() {
      this.addPlaceModalConfig.visible = false;
    },
    // 清空
    clearData(type, list) {
      let str = '';
      if (type === 'org') {
        str = 'orgReqVos';
      } else {
        str = 'userReqVos';
      }
      this[list] = [];
      this.value[str] = [];
      this.$emit('input', this.value);
    },
    // 删除
    delTable(row, type, list) {
      let str = '';
      if (type === 'org') {
        str = 'orgReqVos';
      } else {
        str = 'userReqVos';
      }
      // const valueIndex = this.value[str] ? this.value[str].findIndex((val) => val.id === row.id) : -1;
      const index = this[list].findIndex((val) => val.id === row.id);
      if (index > -1) {
        this[list].splice(index, 1);
        this.value[str] = JSON.parse(JSON.stringify(this[list]));
      }
      // if (valueIndex) {
      //   this.value[str].splice(valueIndex, 1);
      // }
      this.$emit('input', this.value);
    },
    // 获取打卡类型数据
    getSelectRow(e) {
      this.selectRow = e;
    },
    // 添加签到日期
    addDate() {
      this.dateReqVos.push({
        checkInDate: '',
      });
    },
    // 选择签到日期
    getDayChange(index, e) {
      this.dateReqVos[index].checkInDate = e;
      this.dateReqVos[index].startTime = e && e.length > 0 ? e[0] : null;
      this.dateReqVos[index].endTime = e && e.length > 0 ? e[1] : null;
      this.value.dateReqVos = this.dateReqVos;
      this.$emit('input', this.value);
    },
    // 删除签到日期
    delDate(index) {
      this.dateReqVos.splice(index, 1);
    },
    // 获取组织数据
    submitOrg(e) {
      this.isOrg = false;
      this.orgList = e;
      this.value.orgReqVos = JSON.parse(JSON.stringify(this.getReset(this.value.orgReqVos, e, 'id')));
      this.$emit('input', this.value);
    },
    // 获取客户数据
    submitAdd(e) {
      this.isCustomer = false;
      this.customerList = JSON.parse(JSON.stringify(this.getReset(this.customerList, e, 'id')));
      this.value.userReqVos = JSON.parse(JSON.stringify(this.getReset(this.value.userReqVos, e, 'id')));
      this.$emit('input', this.value);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 提交弹窗数据
    submit() {
      if (this.selectRow.length) {
        let str = '';
        const groupTypeReqVos = [];
        this.selectRow.map((v, k) => {
          const item = v;
          groupTypeReqVos.push({
            sfaCheckInTypeId: item.id,
          });
          str += item.typeName + ((k === this.selectRow.length - 1) ? '' : ',');
          return item;
        });
        this.value.typeNames = str;
        this.value.groupTypeReqVos = groupTypeReqVos;
        this.$emit('input', this.value);
        this.$forceUpdate();
        this.modalConfig.visible = false;
      } else {
        this.$message.error('请选择打卡类型');
      }
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
  position: relative;
}
/deep/ .el-tabs__item{
  line-height: 32px;
  height: 32px;
}
/deep/ .el-radio__label{
  font-size: 12px;
}
.el-col {
  padding: 0 !important;
}
.formTitle{
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}
.search{
  position: relative;
}
.el-icon-search{
  position: absolute;
  right: 10px;
  font-size: 18px;
  z-index: 1;
  top: 7px;
  cursor: pointer;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
.mt20{
  margin-top: 20px;
}
.mt10{
  margin-top: 10px;
}
.mr20{
  margin-right: 20px;
}
.pl5{
  padding-left: 5px;
}
.el-icon-circle-plus-outline, .el-icon-remove-outline{
  font-size: 20px;
  position: absolute;
  right: -25px;
  top: 6px;
  cursor: pointer;
}
.el-icon-remove-outline{
  right: -50px;
}
.cursor{
  cursor: pointer;
  color: red;
}
.footer {
  margin-top: 10px;
}
</style>
