<script>
import TablePage from '../../../../../../../components/table_page';
import DataTypeForm from './dakaTypeFrom.vue';

export default {
  extends: TablePage,
  components: {
    DataTypeForm,
  },
  data() {
    return {
      typeList: [], // 打卡类型列表
      requestUrl: '/sfa/sfacheckintype/list',
      formConfig: {},
      isCalculateHeight: false,
      modalConfig: {
        title: '打卡类型',
        visible: false,
        type: 'Modal',
        width: 800,
      },
    };
  },
  watch: {
    selectRow(val) {
      this.$emit('selectRow', val);
    },
  },
  async created() {
    const columns = await this.getConfigList('sfa_attendance_signgroup_add_type_list');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'timeRequire') {
        rowData.formatter = ({ row }) => {
          if (row.appointTime === 'Y') {
            return `${row.beginTime}-${row.endTime}`;
          }
          return row.appointTimeName;
        };
      }
      return rowData;
    });
    this.configs.columns = columns;
  },
  methods: {
    modalClick({ val, row }) {
      const urls = '';
      const text = '';
      if (val.code === 'add') {
        this.formConfig.type = val.code;
        this.modalConfig.title = '打卡类型新增';
        this.formName = 'DataTypeForm';
        this.openModal();
      } else if (val.code === 'edit') {
        this.formConfig.type = val.code;
        this.formConfig.id = row.id;
        this.formName = 'DataTypeForm';
        this.modalConfig.title = '打卡类型编辑';
        this.openModal();
      }
    },
  },
};
</script>
<style lang="less" scoped>
  .mb20{
    margin-bottom: 20px;
  }
</style>
