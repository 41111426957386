<script>
import Form from '../../../../../../../components/form';

export default {
  extends: Form,
  async created() {
    const that = this;
    const rule = await this.getFormRule('sfa_attendance_signgroup_place');
    this.rule = rule.map((item) => {
      let v = item;
      if (v.field === 'address') {
        v = Object.assign(v, {}, { props: { disabled: true } });
      } else if (v.field === 'signPlaceScope') {
        v.on = {
          change(val) {
            that.$emit('scopeChange', val);
          },
        };
      }
      return v;
    });
    // 默认选中打卡范围 100m
    this.setValue({
      signPlaceScope: '100',
    });
    this.reload(this.rule);
  },
};
</script>
